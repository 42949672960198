import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import { CurtainDesc } from "../graphql/CurtainSmallQueries"
import PageHeader from "./PageHeader"
import { AllCurtain } from "../graphql/AllCurtainQuery"
import { useTranslation } from "react-i18next"
import Card from "../components/Card"
import GeneralDescription from "../components/GeneralDescription"
import PageTitle from "../components/PageTitle"
import SEO from "../components/seo"

const CurtainMainPageTemplate = () => {
  const headerImage = CurtainDesc()[0].image ?? undefined
  const headerTitle = CurtainDesc()[0].name ?? undefined
  const md = CurtainDesc()[0].md ?? undefined

  const { i18n } = useTranslation()
  const currentLanguage = i18n.language
  const all = AllCurtain() ?? undefined
  const totalProduct = all.length
  const [componentData, setComponentData] = useState(all)
  useEffect(() => {
    setComponentData(all)
  }, [currentLanguage])

  const cards = componentData.map((item, index) => {
    return <Card key={index} data={item} />
  })

  const totalCards = cards.length

  return (
    <Layout>
      <SEO title={headerTitle} />
      {/* <PageHeader image={headerImage} title={headerTitle} tall /> */}
      <PageTitle title={headerTitle} />
      <div className="max-w-screen-lg mx-auto my-10">
        <GeneralDescription desc={md} sectionName={headerTitle} />
        <div
          className={`grid sm:gap-4 mx-2 mt-10 md:grid-cols-4 sm:grid-cols-3 gap-2 md:bg-white relative z-10 md:p-10  md:mx-4 lg:mx-0 ${
            totalCards === 1 ? "grid-cols-1" : "grid-cols-2"
          }`}
        >
          {cards}
        </div>
      </div>
    </Layout>
  )
}

export default CurtainMainPageTemplate
